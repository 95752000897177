export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0];

export const dictionary = {
		"/[[preview=preview]]/contact": [~4],
		"/[[preview=preview]]/insights": [~5],
		"/[[preview=preview]]/insights/[uid]": [~6],
		"/[[preview=preview]]/service": [~7],
		"/[[preview=preview]]/service/[uid]": [~8],
		"/slice-simulator": [12],
		"/[[preview=preview]]/subscribed": [~9],
		"/[[preview=preview]]/work": [~10],
		"/[[preview=preview]]/work/[uid]": [~11],
		"/[[preview=preview]]": [~2],
		"/[[preview=preview]]/[uid]": [~3]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';